import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DevApproach.css";
import { cardData } from "./CardData";

const Card = ({ title, content, icon }) => {
  return (
    <div className="card" data-aos="fade-up">
      <div id="icon-container">
        {typeof icon === "string" ? (
          <img src={icon} id="icon" alt="" />
        ) : (
          <FontAwesomeIcon id="icon" icon={icon} />
        )}
      </div>
      <div className="user-info">
        <h2 className="card-title">{title}</h2>
        <p className="card-content">{content}</p>
      </div>
    </div>
  );
};

const DevApproach = () => {
  return (
    <>
      <div className="Devcircle">
        <div className="topDev"></div>
      </div>
      <div className="card-container">
        {cardData.map((card, index) => (
          <Card
            key={index}
            icon={card.icon}
            title={card.title}
            content={card.content}
          />
        ))}
      </div>
      <div className="Devcircle1">
        <div className="bottomDev"></div>
      </div>
    </>
  );
};

export default DevApproach;
