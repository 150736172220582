import React, { useEffect, useState } from "react";
import HomePng from "../../assets/Home.png";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
AOS.init({
  duration: 3000,
});

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = HomePng;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <div className="Home">
      <div className="home-left">
        <h4>
          Great{" "}
          <span data-aos="zoom-in" className="product">
            Product
          </span>{" "}
          is
        </h4>
        <h3>
          built by great{" "}
          <span data-aos="zoom-in" className="teams">
            teams
          </span>
        </h3>
        <p data-aos="fade-right">
          We help and manage a team of world-class developers to bring your
          vision to life.
        </p>
        <Link
          to="Services"
          smooth={true}
          offset={-200}
          duration={2000}
          href="#Services"
        >
          <button data-aos="fade-up-left" className="btn-Home" type="">
            Let's get started!
          </button>
        </Link>
      </div>
      <div className="home-right">
        {imageLoaded ? (
          <img src={HomePng} data-aos="fade" className="home-img" alt="" />
        ) : (
          <div className="placeholder-img">Loading...</div>
        )}
      </div>
      <div className="circle"></div>
    </div>
  );
};

export default Home;
