import React, { useState } from "react";
import "./TechStack.css";
import MongoDb from "../../assets/MongoDB.png";
import MySQL from "../../assets/MySQL.png";
import NETCore from "../../assets/NETCore.png";
import NodeJS from "../../assets/NodeJS.png";
import Java from "../../assets/Java.png";
import JavaScript from "../../assets/JavaScript.png";
import CSharp from "../../assets/CSharp.png";
import Html from "../../assets/Html.png";
import Css from "../../assets/CSS3.png";
import ReactLogo from "../../assets/React.png";
import Angular from "../../assets/Angular.png";
import Sql from "../../assets/Sql.png";
import Php from "../../assets/PHP.png";
import Mssql from "../../assets/Mssql.png";
import AspNet from "../../assets/ASP.png";
import Cloud from "../../assets/Cloud.png";
import PerfTest from "../../assets/PerfTest.png";
import FuncTest from "../../assets/FuncTest.png";

const images = {
  Backend: [NodeJS, Php, NETCore, Sql, CSharp, Java, JavaScript],
  Frontend: [Html, Css, JavaScript, ReactLogo, Angular, AspNet],
  Databases: [MySQL, MongoDb, Cloud, Mssql],
  "Software Testing": [PerfTest, FuncTest],
};

const TechStack = () => {
  const [selectedCategory, setSelectedCategory] = useState("Backend");

  const categories = ["Frontend", "Backend", "Databases", "Software Testing"];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="TechStack" data-aos="fade-up">
      <div className="Tech-Heading">
        {categories.map((category, index) => (
          <h4
            key={index}
            className={category === selectedCategory ? "active" : ""}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </h4>
        ))}
      </div>
      <div className="grid-container">
        {images[selectedCategory] ? (
          images[selectedCategory].map((image, index) => (
            <div className="grid-item" key={index}>
              <img src={image} alt={image.alt || "Tech Icon"} />
            </div>
          ))
        ) : (
          <p>No images found for this category</p>
        )}
      </div>
    </div>
  );
};

export default TechStack;
