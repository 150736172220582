import React from "react";
import Counting from "./Components/Counter/Counting";
import TwoCards from "./Components/DevApproach/DevApproach";
import Home from "./Components/Home/Home";
import NavBar from "./Components/NavBar/NavBar";
import Services from "./Components/Services/Services";
import TechStack from "./Components/TechSatck/TechStack";
import Tittle from "./Components/Tittle/Tittle";
import Contactus from "./Components/Contactus/Contactus";
import Footer from "./Components/Footer/Footer";
import VideoPlayer from "./Components/VideoPlayer/VideoPlayer";
import { useState, useEffect } from "react";
import Business from "./Components/Business/Business";
import BpoService from "./Components/BPOService/BpoService";
import Aboutus from "./Components/About/Aboutus";
import HashLoader from "react-spinners/HashLoader";

function App() {
  const [playState, setPlayState] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <HashLoader
          color={"#3d63ea"}
          loading={loading}
          style={{ alignItems: "center" }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="App">
          <NavBar />
          <Home />
          <div className="containers">
            <Tittle content="Service We Offer" />
            <Services />
          </div>
          <div className="container">
            <Aboutus setPlayState={setPlayState} />
          </div>
          <div className="containers">
            <Tittle subtitle="Our design and" content="development approach" />
            <TwoCards />
          </div>
          <div className="container">
            <Tittle subtitle="Our" content="Tech Stack" />
            <TechStack />
          </div>
          <div className="containers">
            <Counting />
          </div>
          <div className="container">
            <Tittle subtitle="Way of Our" content="Business" />
            <Business />
          </div>
          <div className="containers">
            <Tittle subtitle=" Our" content="BPO Services" />
            <BpoService />
          </div>
          <div className="container">
            <Tittle subtitle="Contact us" />
          </div>
          <Contactus />
          <Footer />
          <VideoPlayer playState={playState} setPlayState={setPlayState} />
        </div>
      )}
    </>
  );
}

export default App;
