import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AboutImg from "../../assets/Mask Group.png";
import InfoImg from "../../assets/about_img.png";
import Software from "../../assets/Info-Img.png";
import MiddleImg from "../../assets/MaskGroup2.png";
import MiddleOverlay from "../../assets/Group 7.png";
import VisionMissionImg from "../../assets/VisionMission.png";
import CEOImg from "../../assets/dummy-man.png";
import Footer from "../Footer/Footer";
import "./MoreInfo.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});

function MoreInfo() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="MoreInfo">
        <div className="Info">
          <button className="Homebtn" onClick={goToHome}>
            Home
          </button>
          <img className="MoreInfo-img" src={AboutImg} alt="" />
          <img className="MoreInfo-img-2" src={InfoImg} alt="" />
          <h3>About</h3>
        </div>
        <div className="Info-content" data-aos="fade-up">
          <p>
            Hands down, our culture is our competitive advantage. It’s what
            fuels our innovative approach to delivering end-to-end customer
            experience solutions backed by next-gen technology. It’s also what
            makes us stand out from the competition.{" "}
          </p>
          <p>
            Invosys was formed in 2012 as a Software Solutions Company with a
            vision to provide Innovative, Quality, High performance, and
            Reliable IT Solutions. The company was founded on the principle of
            leveraging cutting-edge technology to address complex business
            challenges and empower organizations to thrive in the digital era.
          </p>
        </div>
        <div className="soft-img">
          <img data-aos="slide-right" src={Software} alt="" />
        </div>
        <h3 data-aos="fade-up" className="section-heading">
          What We Do
        </h3>
        <hr data-aos="fade-up" className="section-divider" />
        <div data-aos="fade-up" className="we-do">
          <div className="we-do-left">
            <p>
              Invoys helps you to scale your business with a team of experts, at
              a fraction of the price!
            </p>
            <p>
              We’re here to empower our clients to take back control of their
              business. Our mission keeps us focused and accountable, our vision
              drives us and our values dictate how we succeed. 
            </p>
            <button
              className="btn"
              style={{ marginTop: "30px", background: "#474665" }}
              onClick={goToHome}
            >
              Explore Services
            </button>
          </div>
          <div className="we-do-right">
            <p>
              To best understand how we are different and what we are offering,
              Invosys offers unique set of services to cater our clients needs
              which includes
            </p>
            <p>- Software Development</p>
            <p>- Web Development</p>
            <p>- Android Development</p>
            <p>- Bulk SMS</p>
            <p>- Email Marketting</p>
            <p>- Web Services</p>
            <p>- Enterprise Mobility Solution</p>
            <p>- Enterprise Web Application</p>
          </div>
        </div>
        <div data-aos="fade-up" className="Info" style={{ marginTop: "80px" }}>
          <img className="MoreInfo-img" src={MiddleImg} alt="" />
          <img className="MoreInfo-img-2" src={MiddleOverlay} alt="" />
          <p className="Info-p">
            We love to enhance, accelerate and transform companies that aim to
            have a positive impact on people, our planet, or both.
          </p>
        </div>
        <h3 data-aos="fade-up" className="section-heading">
          Vission and Mission
        </h3>
        <hr data-aos="fade-up" className="section-divider" />
        <div data-aos="fade-up" className="we-do">
          <div className="VM-left">
            <p>
              To build the most efficient, secure and niche IT solution to meet
              our customer's business needs and improve their profitability.
            </p>
            <p style={{ marginTop: "35px" }}>
              To set new standards in digital world, adopting cutting edge
              technologies & providing state of the art solutions, hence
              maximizing customer benefit, time and all other resources with
              great satisfaction. 
            </p>
          </div>
          <div className="VM-right"></div>
          <img className="VissionImg" src={VisionMissionImg} alt="mission" />
        </div>
        <h3 data-aos="fade-up" className="section-heading">
          Message From CEO
        </h3>
        <hr data-aos="fade-up" className="section-divider" />
        <div data-aos="fade-up" className="message">
          <p>
            Welcome and Thank you for visiting the Invosys website. I am
            Vasagar, Chief Executive Officer.
          </p>
          <p>
            Our Company's purpose, to provide our expertise in composite
            products to provide the absolute best value as measured by quality,
            cost, delivery, and innovation. We are firmly and sincerely
            committed to providing value-packed online services of
            uncompromising quality to businesses and individuals.
          </p>
          <p>
            The values we share are embodied in what goes on at Invosys from day
            to day. Team members must exhibit ethical and honest behavior, and
            Invosys must offer fair, equal conduct in a safe, healthy workplace.
            We believe that in such an environment, sound decision-making and
            effective strategies flow naturally from the give-and-take of daily
            business engagements among all team members.
          </p>
          <p>
            The Invosys business model is our platform for success. I have
            always believed that when it comes to defining and communicating a
            corporation’s vision and goals, conciseness is a virtue.
          </p>
        </div>
        <div className="Ceo">
          <img src={CEOImg} alt="CEO" className="ceo-image" />
          <p className="ceo-name"> - Vasagar</p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MoreInfo;
