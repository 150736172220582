import "./BpoService.css";
import { ReactComponent as WorkIcon } from "../../assets/work.svg";
import { ReactComponent as SchoolIcon } from "../../assets/school.svg";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function BpoService() {
  let workIconStyles = { background: "#E0C3FC" };
  let schoolIconStyles = { background: "#8EC5FC" };

  return (
    <div className="bpo-service">
      <VerticalTimeline>
        {timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";

          return (
            <VerticalTimelineElement
              key={element.id}
              date={element.date}
              dateClassName="date"
              iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
              icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
            >
              <p id="description">{element.description}</p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default BpoService;
