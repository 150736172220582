import Rocket from "../../assets/Rocket.png";
import Code from "../../assets/Code.png";
import Success from "../../assets/Success.png";
import Shield from "../../assets/Shield.png";
import Security from "../../assets/Security.png";
import HeartRate from "../../assets/Heart_Rate.png";

export const cardData = [
  {
    title: "UX Driven Engineering",
    icon: Rocket,
    content:
      "Centering user experience Our engineering approach prioritizes user needs, ensuring intuitive design and seamless functionality for optimal user engagement.",
  },
  {
    title: "Developing Shared Understanding",
    icon: Code,
    content:
      "Fostering collaboration Creating an environment where teams share insights, align goals, and build mutual understanding for successful outcomes.",
  },
  {
    title: "Proven Experience and Expertise",
    icon: HeartRate,
    content:
      "Trusted proficiency Demonstrating extensive experience and expertise through successful delivery across diverse projects and industries worldwide.",
  },
  {
    title: "Security & Intellectual Property (IP)",
    icon: Shield,
    content:
      "Safeguarding assets Ensuring robust security measures while protecting and preserving intellectual property rights within our innovative solutions.",
  },
  {
    title: "Code Reviews",
    icon: Success,
    content:
      "Ensuring quality and consistency Regularly conducting comprehensive code reviews to maintain standards and enhance collaboration among developers.",
  },
  {
    title: "Quality Assurance & Testing",
    icon: Security,
    content:
      "Delivering excellence Our rigorous quality assurance and testing processes ensure reliability, functionality, and user satisfaction across all products.",
  },
];
