import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWhatsapp,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import FootImg from "../../assets/Vector.png";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer data-aos="fade-up">
      <div className="Waves">
        <div className="Wave" id="wave1"></div>
        <div className="Wave" id="wave2"></div>
        <div className="Wave" id="wave3"></div>
        <div className="Wave" id="wave4"></div>
      </div>
      <div className="row">
        <div className="col">
          <img src={FootImg} alt="" />
          <p>
            We help and manage a team of world-class developers to bring your
            vision of life.
          </p>
        </div>
        <div className="col">
          <h3>
            Contactus
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <p>
            Old No: 10, New No: 158, First Floor,Room No 6, Coral Merchant
            Street, Mannady, George Town, Chennai, Tamil Nadu - 600 001
          </p>
          <p className="email-id">support@invosys.co.in</p>
          <h4>+91 9962390088</h4>
        </div>
        <div className="col">
          <h3>
            Links
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <ul>
            <li>
              <Link
                to="Home"
                smooth={true}
                offset={-100}
                duration={500}
                href="#Home"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="Services"
                smooth={true}
                offset={-200}
                duration={500}
                href="#Services"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                offset={-140}
                duration={500}
                href="#About"
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to="TechStack"
                smooth={true}
                offset={-310}
                duration={500}
                href="#TechStack"
              >
                Tech Stack
              </Link>
            </li>
            <li>
              <Link
                to="business-container"
                smooth={true}
                offset={-290}
                duration={500}
                href="#Business"
              >
                Business
              </Link>
            </li>
            <li>
              <Link
                to="bpo-service"
                smooth={true}
                offset={-270}
                duration={500}
                href="#BPO"
              >
                BPO Services
              </Link>
            </li>
          </ul>
        </div>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/InvoSysSoftware"
            aria-label="Facebook"
          >
            <FontAwesomeIcon className="fab facebook " icon={faFacebook} />
          </a>
          <a href="/" aria-label="WhatsApp">
            <FontAwesomeIcon className="fab whatsapp " icon={faWhatsapp} />
          </a>
          <a href="/" aria-label="LinkedIn">
            <FontAwesomeIcon className="fab linkdin" icon={faLinkedinIn} />
          </a>
          <a href="https://twitter.com/ELogiX2" aria-label="Twitter">
            <FontAwesomeIcon className="fab twitter" icon={faTwitter} />
          </a>
        </div>
      </div>
      <hr />
      <p className="text">
        Copyright © 2019, Invoking System Pvt. Ltd. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
