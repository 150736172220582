import React from "react";
import "./Business.css";
import FirstImg from "../../assets/Rectangle 17.png";
import SecondImg from "../../assets/Rectangle 18.png";
import ThirdImg from "../../assets/Rectangle 19.png";

const Business = () => {
  return (
    <div className="business-container">
      <div className="section">
        <div className="left">
          <h2 data-aos="fade-right">Fixed Cost Model</h2>
          <p data-aos="fade">
            If you have a project that is short, well defined scope and all
            requirements are clear, then our fixed cost model is best suitable.
            Over here, we guarantee on time delivery of the work as per
            requirements and cost which were predefined. If required, we appoint
            a team that would best able to meet your expectations on time.
          </p>
          <p data-aos="fade">
            Our<span className="about-content">delivery model</span> helps you
            cut costs and deliver within budget.
          </p>
        </div>
        <div className="right" data-aos="fade-up">
          <img src={FirstImg} alt="First " />
        </div>
      </div>
      <div className="section">
        <div className="left" data-aos="fade-up">
          <img src={SecondImg} alt="Second " />
        </div>
        <div className="right">
          <h2>Time & Material Model</h2>
          <p data-aos="fade">
            For clients whose requirements are not clear and difficult to
            estimate timeline or for some R&D kind of work our T & M model is
            the best fit. In this model, there is a flexibility for client as
            they invoiced only for the hours that are invested to perform the
            work.
          </p>
          <p data-aos="fade">
            Our<span className="about-content">delivery model</span> helps you
            cut costs and deliver within budget.
          </p>
        </div>
      </div>
      <div className="section">
        <div className="left">
          <h2 data-aos="fade-right">Hire a Programmer</h2>
          <p data-aos="fade">
            For clients whose requirements are regularly change and who wants
            dedicated developers to work on any project our monthly hiring model
            is the highly recommended model. In this model, there is a
            flexibility for clients to add or remove resources as per their
            requirement.
          </p>
          <p data-aos="fade">
            Our<span className="about-content">delivery model</span> helps you
            cut costs and deliver within budget.
          </p>
        </div>
        <div className="right" data-aos="fade-up">
          <img src={ThirdImg} alt="Third " />
        </div>
      </div>
    </div>
  );
};

export default Business;
