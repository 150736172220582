import React, { useRef } from "react";
import "./VideoPlayer.css";
import video from "../../assets/Company-Intro.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

function VideoPlayer({ playState, setPlayState }) {
  const player = useRef(null);

  const toggleMuteAndPause = () => {
    const videoElement = player.current.querySelector("video");
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
    videoElement.muted = !videoElement.muted;
  };

  return (
    <div className={`Video-player ${playState ? "" : "hide"}`} ref={player}>
      <FontAwesomeIcon
        className="cancel-btn"
        onClick={() => {
          toggleMuteAndPause();
          setPlayState(false);
        }}
        icon={faPowerOff}
      />
      <video src={video} autoPlay muted controls></video>
    </div>
  );
}

export default VideoPlayer;
