import React, { useState } from "react";
import Count from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Counting.css";

function Counting() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="counter-div">
        <div className="Count-circle">
          <div className="Count-top"></div>
        </div>
        <h1 data-aos="flip-right">
          {counterOn && <Count start={0} end={300} duration={3} delay={0} />}+
          <p>Clients</p>
        </h1>
        <h1 data-aos="flip-right">
          {counterOn && <Count start={0} end={6} duration={3} delay={0} />}+
          <p>Products</p>
        </h1>
        <h1 data-aos="flip-right">
          {counterOn && <Count start={0} end={5} duration={3} delay={0} />}+
          <p>Years</p>
        </h1>
        <div className="Count-circle1">
          <div className="Count-bottom"></div>
        </div>
      </div>
    </ScrollTrigger>
  );
}

export default Counting;
