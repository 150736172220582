import React from "react";
import "./Aboutus.css";
import About_img from "../../assets/Video_frame.png";
import Play_icon from "../../assets/Video_icon.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});

const Aboutus = ({ setPlayState }) => {
  return (
    <div className="about">
      <div className="about-left">
        <div className="line"></div>
        <div className="about-heading" data-aos="fade-right">
          <h3>Leading companies trust us</h3>
          <h2>to develop software</h2>
        </div>

        <p>
          We<span className="about-content">add development capacity </span>to
          tech teams. Our value isn’t limited to building teams but is equally
          distributed across the project lifecycle. We are a custom software
          development company that guarantees the successful delivery of your
          project.
        </p>
        <p className="moreinfo">
          <Link className="a-link" to="/More-Info">
            See more Information
            <FontAwesomeIcon className="Rightarrow" icon={faArrowRight} />
          </Link>
        </p>
      </div>
      <div className="about-right" data-aos="zoom-in">
        <img src={About_img} className="about-img" alt="" />
        <img
          src={Play_icon}
          className="play_icon"
          alt=""
          onClick={() => {
            setPlayState(true);
          }}
        />
      </div>
    </div>
  );
};

export default Aboutus;
