import Code from "../../assets/Code.png";
import Mobile from "../../assets/Mobile.png";
import Dashboard from "../../assets/Dashboard.png";

export const slideData = [
  {
    icon: Code,
    title: "Web Design  Development",
    description:
      "Elevating your online presence Our company specializes in web design and development, crafting tailored solutions to showcase your identity and enhance your digital footprint in today's digital landscape.",
  },
  {
    icon: Mobile,
    title: "Mobile App Development",
    description:
      "Empowering your mobile presence Our company excels in mobile app development, creating customized solutions to elevate your brand and engage users effectively in the dynamic mobile ecosystem.",
  },
  {
    icon: Dashboard,
    title: "E-Mail and Marketing",
    description:
      "Optimizing your outreach Our company specializes in email marketing, leveraging targeted campaigns and compelling content to nurture leads, drive engagement, and maximize your marketing ROI effectively.",
  },
  {
    icon: Mobile,
    title: "Enterprise Mobility Solution",
    description:
      "Transforming workplace mobility Our company delivers enterprise mobility solutions tailored to your business needs, empowering employees with secure access to data and applications for   ",
  },
  {
    icon: Dashboard,
    title: "IT Consulting & Solutions",
    description:
      "Navigating digital transformation: Our company offers expert IT consulting and tailored solutions, guiding businesses through technology challenges to achieve strategic objectives and drive sustainable  ",
  },
];
