import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../../assets/Vector.png";
import { Link } from "react-scroll";
import Menu from "../../assets/menu.png";

function NavBar() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <>
      <nav className="container dark-nav">
        <img src={Logo} className="logo" alt="" />
        <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
          <li>
            <Link
              to="Home"
              smooth={true}
              offset={-100}
              duration={500}
              href="#Home"
              onClick={closeMobileMenu}
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              to="Services"
              smooth={true}
              offset={-200}
              duration={500}
              href="#Services"
              onClick={closeMobileMenu}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="about"
              smooth={true}
              offset={-175}
              duration={500}
              href="#About"
              onClick={closeMobileMenu}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="TechStack"
              smooth={true}
              offset={-340}
              duration={500}
              href="#TechStack"
              onClick={closeMobileMenu}
            >
              Tech Stack
            </Link>
          </li>
          <li>
            <Link
              to="business-container"
              smooth={true}
              offset={-320}
              duration={500}
              href="#Business"
              onClick={closeMobileMenu}
            >
              Business
            </Link>
          </li>
          <li>
            <Link
              to="bpo-service"
              smooth={true}
              offset={-270}
              duration={500}
              href="#Clients"
              onClick={closeMobileMenu}
            >
              BPO Services
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              offset={-220}
              duration={500}
              href="#Contact"
              onClick={closeMobileMenu}
            >
              Contact us
            </Link>
          </li>
          <li>
            <a className="btn" href="https://f-studio.in/">
              Our Products
            </a>
          </li>
        </ul>
        <img src={Menu} alt="" onClick={toggleMenu} className="menu-icon" />
      </nav>
    </>
  );
}

export default NavBar;
