let timelineElements = [
  {
    id: 1,
    description:
      "Digital Signature Certificate - Class 3 for Icegate/DGFT (3 years Validity).",
    icon: "work",
  },
  {
    id: 2,
    description:
      "IEC Fresh / Renewal Re-Validation / Suspension revoke at DGFT Portal.",
    icon: "school",
  },
  {
    id: 3,
    description:
      "Icegate Profile creation for CHA, IEC & various stake holders.",
    icon: "work",
  },
  {
    id: 4,
    description: "AD Code / DBK account creation for IEC.",
    icon: "school",
  },
  {
    id: 5,
    description:
      "Non Receipt of duty drawback incentive on account of PFMS error.",
    icon: "work",
  },
  {
    id: 6,
    description:
      "RODTEP - E-Script Ledger creation & Transfer, IGST - BoE Input credit issues.",
    icon: "school",
  },
  {
    id: 7,
    description: " IGST - Export claim issues , Licence (RODTEP, MEIS) Trading",
    icon: "work",
  },
];

export default timelineElements;
