import React, { useRef, useState, useEffect } from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import { slideData } from "./slideData";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 3000,
});

const Services = () => {
  const slider = useRef();
  const [tx, setTx] = useState(0);
  const [showLeftIcon, setShowLeftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(true);

  useEffect(() => {
    setShowLeftIcon(tx < 0);
    setShowRightIcon(tx > -50);
  }, [tx]);

  const slideForward = () => {
    if (tx > -80) {
      setTx((prevTx) => prevTx - 20);
    }
  };

  const slideBackward = () => {
    if (tx < 0) {
      setTx((prevTx) => prevTx + 20);
    }
  };

  return (
    <div className="Services">
      <div className="circle1">
        <div className="top"></div>
      </div>

      {showRightIcon && (
        <FontAwesomeIcon
          icon={faArrowCircleRight}
          className="next-btn"
          onClick={slideForward}
        />
      )}
      {showLeftIcon && (
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className="back-btn"
          onClick={slideBackward}
        />
      )}
      <div className="slider">
        <ul
          ref={slider}
          style={{
            transform: `translateX(${tx}%)`,
            transition: "transform 0.5s",
          }}
        >
          {slideData.map((slide, index) => (
            <li key={index}>
              <div className="slide" data-aos="slide-right">
                <div className="user-info">
                  {typeof slide.icon === "string" ? (
                    <img src={slide.icon} id="icon" alt="" />
                  ) : (
                    <FontAwesomeIcon id="icon" icon={slide.icon} />
                  )}
                  <div>
                    <h3>{slide.title}</h3>
                  </div>
                </div>
                <p>{slide.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="circle2">
        <div className="bottom"></div>
      </div>
    </div>
  );
};

export default Services;
